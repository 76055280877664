export default {
  label: {
    userProfile: 'User Profile',
    contactAgent: 'Contact Agent',
    myProfile: 'My Profile',
    accountSetting: 'Account Setting',
    logout: 'Logout',
    subscription: 'Subscription',
    activity: 'Activity',
    managePaymentProfile: 'Manage Payment Profile',
    makePayment: 'Make Payment',
    billingData: 'Billing Data',
    viewBalances: 'View Balances',
    viewTransactions: 'View Transactions',
    viewBills: 'View Bills/Invoices',
    aRActivity: 'AR Activity',
    balances: 'Balances',
    accountStatement: 'Account Statement',
    accountNumber: 'Account Number',
    state: 'State',
    city: 'City',
    firstName: 'First Name',
    lastName: 'Last Name',
    street: 'Street',
    address: 'Address',
    country: 'Country',
    email: 'Email',
    phoneNumber: 'Phone Number',
    userId: 'User Id',
    password: 'Password',
    retypePassword: 'Retype Password',
    rememberMe: 'Remember me',
    postalCode: 'Postal Code',
    submit: 'Submit',
    cancel: 'Cancel',
    services: 'Services',
    serviceUnits: 'Service Units',
    id: 'Id',
    accountId: 'Account Id',
    status: 'Status',
    reason: 'Reason',
    initialTerm: 'Initial Term',
    initialTermUnit: 'Initial Term Unit',
    renewalTerm: 'Renewal Term',
    renewalTermUnit: 'Renewal Term Unit',
    trialTerm: 'Trial Term',
    trialTermUnit: 'Trial Term Unit',
    effectiveDate: 'Effective Date',
    type: 'Type',
    parentId: 'Parent Id',
    provisioningId: 'Provisioning Id',
    bundleName: 'Bundle Name',
    packageName: 'Package Name',
    subscriptionId: 'Subscription Id',
    creditCardToken: 'Credit Card Token',
    creditCardCVV: 'Credit Card CVV',
    creditCardExpiry: 'Credit Card Expiry',
    removeCreditCard: 'Remove credit card',
    creditCardNumber: 'Credit Card Number',
    addCreditCard: 'Add Credit Card',
    // token: 'Token',
    // expiry: 'Expiry',
    oneOffAmount: 'One Off Amount',
    applyToInvoiceUnitID: 'Apply to Invoice Unit ID',
    cardOnFile: 'Card On File',
    payNow: 'Pay Now',
    apply: 'Apply',
    addCard: 'Add Card',
    currency: 'Currency',
    grants: 'Grants',
    accumulators: 'Accumulators',
    balance: 'Balance',
    creditLimit: 'Credit Limit',
    amountUsed: 'Amount Used',
    validityStart: 'Validity Start',
    validityEnd: 'Validity End',
    amount: 'Amount',
    transactions: 'Transactions',
    itemId: 'Item Id',
    startDate: 'Start Date',
    endDate: 'End Date',
    search: 'Search',
    searching: 'Searching',
    transactionType: 'Transaction Type',
    transactionUnit: 'Transaction Unit',
    itemDescription: 'Item Description',
    serviceType: 'Service Type',
    NET: 'NET',
    source: 'Source',
    view: 'View',
    previous: 'Previous',
    next: 'Next',
    recurringData: 'Recurring Data',
    usageData: 'Usage Data',
    taxData: 'Tax Data',
    transactionId: 'Transaction Id',
    description: 'Description',
    billUnitId: 'Bill Unit Id',
    serviceUnitId: 'Service Unit Id',
    netAmount: 'Net Amount',
    index: 'Index',
    balanceType: 'Balance Type',
    offerType: 'Offer Type',
    unitPrice: 'Unit Price',
    code: 'Code',
    taxCode: 'Tax Code',
    jurisdiction: 'Jurisdiction',
    taxableAmount: 'Taxable Amount',
    exemptAmount: 'Exempt Amount',
    taxAmount: 'Tax Amount',
    accumulatorId: 'Accumulator Id',
    balanceUnitIndex: 'Balance Unit Index',
    accumulatorStartDate: 'Accumulator Start Date',
    accumulatorEndDate: 'Accumulator End Date',
    grantId: 'Grant Id',
    grantType: 'Grant Type',
    grantStartDate: 'Grant Start Date',
    grantEndDate: 'Grant End Date',
    recurringType: 'Recurring Type',
    recurringUnit: 'Recurring Unit',
    recurringDuration: 'Recurring Duration',
    proration: 'Proration',
    scale: 'Scale',
    cycleStart: 'Cycle Start',
    cycleEnd: 'Cycle End',
    appliedStart: 'Applied Start',
    appliedEnd: 'Applied End',
    rateUnit: 'Rate Unit',
    timeUnit: 'Time Unit',
    zoneUnit: 'Zone Unit',
    impactUnit: 'Impact Unit',
    holidayName: 'Holiday Name',
    salesChannel: 'Sales Channel',
    marketSegment: 'Market Segment',
    accountType: 'Account Type',
    accountSubType: 'Account Sub Type',
    quantity: 'Quantity',
    scaledQuantity: 'Scaled Quantity',
    batchid: 'Batch Id',
    batchFileName: 'Batch File Name',
    OK: 'OK',
    invoiceDetails: 'Invoice Details',
    pendingBills: 'Pending Bills',
    openCloseBills: 'Open / Close Bills',
    total: 'Total',
    nonPayingTotal: 'Non-Paying Total',
    billTotal: 'Bill Total',
    due: 'Due',
    dueDate: 'Due Date',
    invoiceNumber: 'Invoice Number',
    invoiceStatus: 'Invoice Status',
    invoiceType: 'Invoice Type',
    back: 'Back',
    aRType: 'AR Type',
    arTaxRule: 'Ar Tax Rule',
    arCrDrType: 'AR CR Dr Type',
    invoiceUnitId: 'Invoice Unit Id',
    arOpsData: 'AR Ops Data',
    invoiceId: 'Invoice Id',
    documentType: 'Document Type',
    invoiceDate: 'Invoice Date',
    subTotal: 'Sub Total',
    taxes: 'Taxes',
    folioDate: 'Folio Date',
    folioId: 'Folio Id',
    openingBalance: 'Opening Balance',
    closingBalance: 'Closing Balance',
    clear: 'Clear',
    forgotPassword: 'Forgot Password',
    login: 'Login',
    signIn: 'Sign In',
    username: 'Username',
    doNotHaveAAccount: 'Do not have a account ',
    register: 'Register',
    chooseLanguage: 'Choose language',
    accountDetails: 'Account Details',
    creditCardDetails: 'Credit Card Details',
    selectCreatePackage: 'Select/Create Package',
    createYourPackage: 'Create Your Package',
    last4CC: 'Last 4CC',
    packageSelected: 'Package Selected',
    selectPackage: 'Select Package',
    createPackage: 'Create Package',
    createUser: 'Create User',
    currentBalance: 'Current Balance',
    currentBillTotal: 'Current Bill Total',
    lastInvoiceDate: 'Last Invoice Date',
    lastInvoiceTotal: 'Last Invoice Total',
    lastInvoiceDueDate: 'Last Invoice Due Date',
    lastInvoiceDue: 'Last Invoice Due',
    lastPaymentDate: 'Last Payment Date',
    lastPaymentAmount: 'Last Payment Amount',
    crARSinceLastInvoice: 'Cr AR Since Last Invoice',
    drARSinceLastInvoice: 'Dr AR Since Last Invoice',
    accountData: 'Account Data',
    billingProfile: 'Billing Profile',
    purchaseOrder: 'Purchase Order',
    subscriptionData: 'Subscription Data',
    orders: 'Orders',
    viewCreditNotes: 'View Credit Notes',
    viewARActivity: 'View AR Activity',
    reports: 'Reports',
    accountActivity: 'Account Activity',
    collectionActivity: 'Collection Activity',
    collectionUnitId: 'Collection Unit Id',
    daysInCollection: 'Days In Collection',
    lastActionDate: 'Last Action Date',
    closeDate: 'Close Date',
    notes: 'Notes',
    collectionActionsForInvoice: 'Collection Actions For Invoice',
    date: 'Date',
    billingDom: 'Billing Dom',
    billingSegment: 'Billing Segment',
    billingFrequency: 'Billing Frequency',
    invoiceDelivery: 'Invoice Delivery',
    submittedDate: 'Submitted Date',
    action: 'Action',
    entityType: 'Entity Type',
    activityDate: 'Activity Date',
    accountInfo: 'Account Info',
    customerActivity: 'Customer Activity',
    paymentProfileId: 'Payment Profile Id',
    creditProfileIndex: 'Credit Profile Index',
    customerSegment: 'Customer Segment',
    sellingCompany: 'Selling Company',
    lineOfBusiness: 'Line of Business',
    legalEntity: 'Legal Entity',
    addresses: 'Addresses',
    paymentProfile: 'Payment Profile',
    customAttributes: 'Custom Attributes',
    contact: 'Contact',
    salutation: 'Salutation',
    position: 'Position',
    organization: 'Organization',
    phone: 'Phone',
    roles: 'Roles',
    useAsBilling: 'Use As Billing',
    useAsSoldTo: 'Use As Sold To',
    useAsPayment: 'Use As Payment',
    useAsService: 'Use as service',
    middleName: 'Middle Name',
    extraLine: 'Extra Line',
    landmark: 'Landmark',
    paymentTerm: 'Payment Term',
    paymentMethod: 'Payment Method',
    cardToken: 'Card Token',
    cardExp: 'Card Exp',
    noteType: 'Notes Type',
    creditNotes: 'Credit Notes',
    externalPurchaseOrder: 'External Purchase Order',
    PONumber: 'PO Number',
    name: 'Name',
    PODescription: 'PO Description',
    PO: 'PO',
    clientId: 'Client Id',
    salesAgentId: 'Sales Agent Id',
    keyLink: 'Key Links',
    linkText: 'Link Text',
    URL: 'URL',
    advertisement: 'Advertisement',
    advHeader: 'Adv Header',
    advShortDescription: 'Adv ShortDescription',
    home: 'Home',
    percent: 'Percent',
    selectInvoice: 'Select Invoice',
    select: 'Select',
    download: 'Download',
    viewPayment: 'View Payment',
    externalId: 'External Id',
    remainingAmount: 'Remaining Amount',
    paymentDate: 'Payment Date',
    payments: 'Payments',
    request: 'Request',
    response: 'Response',
    entityId: 'Entity Id',
    numberPortability: 'Number Portability',
    'historicalCDR’s': 'Historical CDR’s',
    excel: 'Excel',
    pdf: 'Pdf',
    emails: 'Email(‘s)',
    addAEmail: 'Add an Email',
    invoiceCurrency: 'Invoice Currency',
    totalFunctionalCurrency: 'Total </br> (Functional Currency)',
    functionalCurrency: 'Functional Currency',
    totalFunctionalCurrencyExport: 'Total (Functional Currency)',
    exchangeRate: 'Exchange Rate',
    commName: 'Comm Name',
    commercialName: 'Commercial Name',
    folioStatus: 'Folio Status',
    historicalFolios: 'Historical Folios',
    CDRReports: 'CDR Reports',
    platform: 'Platform',
    fileName: 'File Name',
    fileId: 'File Id',
    dateTime: 'Date/Time',
    totalInputRecords: 'Total Input Records',
    totalImportedRecords: 'Total Imported Records',
    totalErrorRecords: 'Total Error Records',
    rawUsageFiles: 'Raw Usage Files',
    switchName: 'Switch Name',
    aPartyNumber: 'A Party Number',
    bPartyNumber: 'B Party Number',
    aPartyNumberStartRange: 'A Party Number Start Range',
    aPartyNumberEndRange: 'A Party Number End Range',
    bPartyNumberStartRange: 'B Party Number Start Range',
    bPartyNumberEndRange: 'B Party Number End Range',
    sourceIpAddress: 'Source Ip Address',
    destinationIpAddress: 'Destination Ip Address',
    rawBroadsoftZeroDurationCDR: 'Raw Broadsoft Zero Duration CDR',
    sServiceProvider: 'S_SERVICEPROVIDER',
    sUserNumber: 'S_USERNUMBER',
    sGroupNumber: 'S_GROUPNUMBER',
    sDirection: 'S_DIRECTION',
    sCallingNumber: 'S_CALLINGNUMBER',
    sCalledNumber: 'S_CALLEDNUMBER',
    sStartTime: 'S_STARTTIME',
    sAnswerIndicator: 'S_ANSWERINDICATOR',
    sAnswerTime: 'S_ANSWERTIME',
    sReleaseTime: 'S_RELEASETIME',
    sTerminationCause: 'S_TERMINATIONCAUSE',
    sDailedDigits: 'S_DIALEDDIGITS',
    sCallCategory: 'S_CALLCATEGORY',
    sNetworkCallType: 'S_NETWORKCALLTYPE',
    sNetworkTranslatedNumber: 'S_NETWORKTRANSLATEDNUMBER',
    sReleasingParty: 'S_RELEASINGPARTY',
    sRoute: 'S_ROUTE',
    sNetworkCallId: 'S_NETWORKCALLID',
    sCodec: 'S_CODEC',
    sAccessDeviceAddress: 'S_ACCESSDEVICEADDRESS',
    sGroup: 'S_GROUP',
    sDepartment: 'S_DEPARTMENT',
    sAuthorizationCode: 'S_AUTHORIZATIONCODE',
    sOriginalCalledNumber: 'S_ORIGINALCALLEDNUMBER',
    sOriginalCalledPresentationIndicator: 'S_ORIGINALCALLEDPRESENT_INDIC',
    sOriginalCalledReason: 'S_ORIGINALCALLEDREASON',
    sRedirectingNumber: 'S_REDIRECTINGNUMBER',
    sRedirectingPresentationIndicator: 'S_REDIRECTINGPRESENT_INDIC',
    sRedirectingReason: 'S_REDIRECTINGREASON',
    sChargeIndicator: 'S_CHARGEINDICATOR',
    sVoicePortalInvocationTime: 'S_VOICEPORTALCALLING_INVOCTIME',
    dExtAnswerDateTime: 'D_EXT_ANSWER_DATE_TIME',
    nExtDurationMinutes: 'N_EXT_DURATION_MINUTES',
    nExtDurationSeconds: 'N_EXT_DURATION_SECONDS',
    sReserved: 'S_EXT_RESERVED',
    sUserid: 'S_USERID',
    sOtherPartyName: 'S_OTHERPARTYNAME',
    durationMinutesStart: 'Duration Minutes Start',
    durationMinutesEnd: 'Duration Minutes End',
    incomingRoute: 'Incoming Route',
    outgoingRoute: 'Outgoing Route',
    failureReason: 'Failure Reason',
    idd: 'IDD',
    IDO: 'IDO',
    reserved: 'Reserved',
    rawBroadsoft: 'Raw Broadsoft',
    metaswitch: 'Metaswitch',
    metaswitchZero: 'Metaswitch Zero',
    callingNumber: 'Calling Number',
    calledNumber: 'Callled Number',
    answerTime: 'Answer Time',
    sreserved: 'S_Reserved',
    disconnectReason: 'Disconnect Reason',
    failureCode: 'Failure Code',
    callStatus: 'Call Status',
    terminatingAccess: 'Terminating Access',
    extIddAbc: 'Ext IDD ABC',
    extIddBcd: 'Ext IDD BCD',
    commercial: 'Commercial',
    callDuration: 'Call Duration',
    extIdoBcd: 'Ext IDO BCD',
    extDurationMinutes: 'Ext Duration Minutes',
    extDurationSeconds: 'Ext Duration Seconds',
    partySipCallIdZero: 'Party Sip Call Id Zero',
    partySipCallIdOne: 'Party Sip Call Id One',
    startTime: 'Start Time',
    endTime: 'End Time',
    rawSMSFiles: 'Raw SMS Files',
    networkId: 'Network Id',
    messageLength: 'Message Length',
    messageType: 'Message Type',
    first20Chars: 'First 20 Chars',
    optTag: 'Opt Tag',
    optLength: 'Opt Length',
    optVal: 'Opt Val',
    origNetworkId: 'Original Network Id',
    host: 'Host',
    esmClass: 'Esm Class',
    gsmsiei: 'Gsmsiei',
    gsmudh: 'Gsmudh',
    messageDeliveryStatus: 'Message Delivery Status',
    extIdCinta: 'Ext Id Cinta',
    messageId: 'Message Id',
    sequenceNumber: 'Sequence Number',
    commandId: 'Command Id',
    commandLength: 'Command Length',
    pduType: 'PDU Type',
    aspName: 'ASP Name',
    messageStatus: 'Message Status',
    submitDate: 'Submit Date',
    addressSrcDigits: 'Address Src Digits',
    srcTon: 'Src Ton',
    srcApi: 'Src Api',
    addressDestDigits: 'Address Dest Digits',
    destTon: 'Dest Ton',
    destApi: 'Dest Api',
    scheduleDeliveryTime: 'Schedule Delivery Time',
    validityPeriod: 'Validity Period',
    protocolId: 'Protocol Id',
    priority: 'Priority',
    registerDelivery: 'Register Delivery',
    replacePresent: 'Replace Present',
    dataCoding: 'Data Coding',
    onDemand: 'Data Customer',
    ipAddress: 'IP Address',
    countLessThan: 'Count <',
    volumeUpload: 'Volume Upload',
    volumeDownload: 'Volume Download',
    recordCount: 'Record Count',
    recordDate: 'Record Date',
    uploadVolume: 'Upload Volume',
    downloadVolume: 'Download Volume',
    details: 'Details',
    invoiceAmount: 'Invoice Amount',
    totalSelectedToPay: 'Total Selected To Pay',
    selectInvoices: 'Select Invoices',
    accountSearch: 'Account Search',
    partyId: 'Party Id',
    groupId: 'Group Id',
    taxId: 'Tax Id',
    foreignTaxId: 'Foreign Tax Id',
    effectiveStartDate: 'Effective Start Date (>=)',
    effectiveEndDate: 'Effective End Date (<)',
    subscriptionStatus: 'Subscription Status',
    accountCategory: 'Account Category',
    fetchParent: 'Fetch Parent',
    childAccount: 'Child Account',
    groupCompany: 'Group Company',
    collectionAgentAssigned: 'Collection Agent Assigned',
    advancedSearch: 'Advanced Search',
    ACCTNo: 'ACCT No',
    collectionAgentId: 'Collection Agent Id',
    paymentId: 'Payment Id',
    notesId: 'Notes Id',
    customerId: 'Customer Id',
    copyrightText: '© Copyright 2024. EMBRIX All Rights Reserved.',
    termsConditions: 'Terms & Conditions',
    PIUsePolicy: 'PI Use Policy',
    privacyPolicy: 'Privacy Policy',
  },

  message: {
    canNotBeBlank: 'can not be blank.',
    noRecord: 'No record has found!',
    cvvMsg: '4 characters.',
    msgDeleteToken: 'Are you sure you want to delete this card token?',
    msgHaveAccount: 'Do you already have an Account No?',
    msgSelectCity: 'Please select City before',
    msgSelectState: 'Please select State before',
    contentLeftViewAccountDetails:
      'Provide basic information as your name and address to create your customer account. Select your UserId and password for your Selfcare access.',
    contentLeftViewCreateUser:
      'You already should have an account with a subscription. Select your UserId and password and link the accountId with your user account.',
    contentLeftViewCreditCardDetails:
      'To add a new credit card, the UI will take you to the merchant account widget and get encrypted tokens back to ensure full PCI compliance. Please provide your details as mandated in the widget.',
    contentLeftViewSelectPackage:
      'Use this option to select a pre-configured package for your new subscription. This is required if you do not have an accountId established for the subscription. Only one package can be selected.',
    contentLeftViewCreatePackage:
      'Use this option to create your own package. Select the Service-type from the drop-down list and select one or more Bundles for the service type. Repeat the same for additional service types.',
  },

  apiMessage: {
    sentEmailSuccessfully: 'Sent Email successfully!',
    createFileSuccessfully: 'Create File successfully!',
    paymentHasGoneToSuspense: 'Payment has gone to Suspense',
    paymentIsSuccessful: 'Payment is successfully!',
    messageSetAccountId: id =>
      `Selfcare context is setup for account ${id} with your search. You can search again to change the Self-care account context`,
  },

  dropdown: {
    subscriptionStatus: {
      ACTIVE: 'ACTIVE',
      SUSPENDED: 'SUSPENDED',
      CLOSED: 'CLOSED',
    },

    termUnit: {
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
      NONE: 'NONE',
    },

    trialTermUnit: {
      DAYS: 'DAYS',
      WEEKS: 'WEEKS',
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
    },

    trialStatus: {
      OPT_IN: 'OPT_IN',
      OPT_OUT: 'OPT_OUT',
    },

    trialTypes: {
      OPT_IN_TRIAL: 'OPT_IN_TRIAL',
      OPT_OUT_TRIAL: 'OPT_OUT_TRIAL',
    },

    serviceType: {
      DEFAULT: 'DEFAULT',
      VOIP: 'VOIP',
      CABLE: 'CABLE',
      UTILITIES: 'UTILITIES',
      RETAIL: 'RETAIL',
      WHOLESALE: 'WHOLESALE',
      WIRELINE: 'WIRELINE',
      TELEPHONY: 'TELEPHONY',
      IPTV: 'IPTV',
      DATA: 'DATA',
      PROFESSIONAL: 'PROFESSIONAL',
      INTERNET: 'INTERNET',
      HOMESECURITY: 'HOMESECURITY',
      VOICE: 'VOICE',
      SMS: 'SMS',
      MMS: 'MMS',
      EMAIL: 'EMAIL',
      EQUIPMENT: 'EQUIPMENT',
      DATACENTER: 'DATACENTER',
    },

    transactionType: {
      ONE_TIME: 'ONE_TIME',
      ONE_TIME_CANCEL: 'ONE_TIME_CANCEL',
      FORWARD_RECURRING: 'FORWARD_RECURRING',
      ARREARS_RECURRING: 'ARREARS_RECURRING',
      USAGE: 'USAGE',
      VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
      TAX: 'TAX',
      MANUAL_TRANSACTION: 'MANUAL_TRANSACTION',
      TRUE_UP: 'TRUE_UP',
      NON_TAX: 'NON_TAX',
    },

    transactionSource: {
      ORDERS: 'ORDERS',
      BILLING: 'BILLING',
      JOBS: 'JOBS',
      COLLECTION: 'COLLECTION',
      MEDIATION: 'MEDIATION',
      BACKOUT: 'BACKOUT',
    },

    accountSubType: {
      SILVER: 'SILVER',
      GOLD: 'GOLD',
      PLATINUM: 'PLATINUM',
      NONE: 'NONE',
    },

    accountType: {
      DIRECT_CUSTOMER: 'DIRECT_CUSTOMER',
      PARTNER: 'PARTNER',
      PARTNER_CUSTOMER: 'PARTNER_CUSTOMER',
      RESELLER: 'RESELLER',
      RESELLER_CUSTOMER: 'RESELLER_CUSTOMER',
      INTERNAL_TEST_ACCOUNT: 'INTERNAL_TEST_ACCOUNT',
      EXTERNAL_TEST_ACCOUNT: 'EXTERNAL_TEST_ACCOUNT',
      BROKER_CHANNEL_PARTNER: 'BROKER_CHANNEL_PARTNER',
      DATA_SUPPLIER: 'DATA_SUPPLIER',
      INTERCOMPANY: 'INTERCOMPANY',
      CONNECTOR_PARTNER: 'CONNECTOR_PARTNER',
      AFFILIATE: 'AFFILIATE',
      SALES_AGENT: 'SALES_AGENT',
      SALES_CONSULTANT: 'SALES_CONSULTANT',
      CLIENT: 'CLIENT',
      PARTY: 'PARTY',
      INTERCONNECT: 'INTERCONNECT',
      NONE: 'NONE',
      DISTRIBUTOR: 'DISTRIBUTOR',
      CARRIER: 'CARRIER',
      ADMIN: 'ADMIN',
      EMPLOYEE: 'EMPLOYEE',
    },

    marketSegment: {
      INDIVIDUAL: 'INDIVIDUAL',
      SMALL_ENTERPRISE: 'SMALL_ENTERPRISE',
      SMB: 'SMB',
      LARGE_ENTERPRISE: 'LARGE_ENTERPRISE',
      NONE: 'NONE',
    },

    rateUnit: {
      EACH: 'EACH',
      DURATION: 'DURATION',
      VOLUME: 'VOLUME',
      QOS: 'QOS',
      DOWNLINK: 'DOWNLINK',
      UPLINK: 'UPLINK',
      SIZE: 'SIZE',
      QUANTITY: 'QUANTITY',
      VOLUMEDOWNLOAD: 'VOLUMEDOWNLOAD',
      VOLUMEUPLOAD: 'VOLUMEUPLOAD',
      NONE: 'NONE',
    },

    salesChannel: {
      WEB: 'WEB',
      POS: 'POS',
      ECOMMERCE: 'ECOMMERCE',
      ADVERTISEMENT: 'ADVERTISEMENT',
      TELE_MARKET: 'TELE_MARKET',
      AGENT_CARE: 'AGENT_CARE',
      SELFCARE: 'SELFCARE',
      NONE: 'NONE',
    },

    timeUnitPricing: {
      WORKWEEK: 'WORKWEEK',
      WEEKEND: 'WEEKEND',
      PEAKHOURS: 'PEAKHOURS',
      OFFPEAKHOURS: 'OFFPEAKHOURS',
      HOLIDAYSPECIAL: 'HOLIDAYSPECIAL',
      ANYTIME: 'ANYTIME',
      NONE: 'NONE',
    },

    prorationType: {
      FULL: 'FULL',
      ZERO: 'ZERO',
      PRORATE: 'PRORATE',
    },

    recurringUnit: {
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
    },

    recurringType: {
      FORWARD: 'FORWARD',
      ARREARS: 'ARREARS',
    },

    billStatus: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
    },

    invoiceStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      COLLECTION: 'COLLECTION',
      // SENT: 'SENT',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      DISAPPROVED: 'DISAPPROVED',
      SYNC_FAILED: 'SYNC_FAILED',
      // SEND_FAILED: 'SEND_FAILED',
      // STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      // STAMPED: 'STAMPED',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
    },

    invoiceUnitType: {
      REGULAR: 'REGULAR',
      CORRECTIVE: 'CORRECTIVE',
      REGENERATED: 'REGENERATED',
    },

    arOpsType: {
      BILL_ADJUSTMENT: 'BILL_ADJUSTMENT',
      TRANSACTION_ADJUSTMENT: 'TRANSACTION_ADJUSTMENT',
      BILL_DISPUTE: 'BILL_DISPUTE',
      TRANSACTION_DISPUTE: 'TRANSACTION_DISPUTE',
      BILL_SETTLEMENT: 'BILL_SETTLEMENT',
      ACCOUNT_WRITEOFF: 'ACCOUNT_WRITEOFF',
      INVOICE_WRITEOFF: 'INVOICE_WRITEOFF',
      TRANSACTION_SETTLEMENT: 'TRANSACTION_SETTLEMENT',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT: 'PAYMENT',
      REFUND: 'REFUND',
      CHARGEBACK: 'CHARGEBACK',
      TAX: 'TAX',
      TAX_ADJUSTMENT: 'TAX_ADJUSTMENT',
      TAX_SETTLEMENT: 'TAX_SETTLEMENT',
      TAX_DISPUTE: 'TAX_DISPUTE',
      TAX_WRITEOFF: 'TAX_WRITEOFF',
      TAX_WRITEOFF_REVERSAL: 'TAX_WRITEOFF_REVERSAL',
    },

    taxRule: {
      WITH_TAX: 'WITH_TAX',
      WITHOUT_TAX: 'WITHOUT_TAX',
      TAX_ONLY: 'TAX_ONLY',
    },

    CRDRType: {
      CREDIT: 'CREDIT',
      DEBIT: 'DEBIT',
    },

    source: {
      EXTERNAL: 'EXTERNAL',
      SELF_CARE: 'SELF_CARE',
      AGENT_CARE: 'AGENT_CARE',
      RERATING: 'RERATING',
      PAYMENTS: 'PAYMENTS',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      INTERNAL_BATCH_JOBS: 'INTERNAL_BATCH_JOBS',
    },

    arCrDrType: {
      DEBIT: 'DEBIT',
      CREDIT: 'CREDIT',
    },

    statusCollections: {
      OPEN: 'OPEN',
      CLOSED: 'CLOSED',
      CUSTOMER_CONTACT_INITIATED: 'CUSTOMER_CONTACT_INITIATED',
      CUSTOMER_CONTACT_ESTABLISHED: 'CUSTOMER_CONTACT_ESTABLISHED',
      PROMISE_TO_PAY: 'PROMISE_TO_PAY',
      PROMISE_BROKEN: 'PROMISE_BROKEN',
      SUSPEND: 'SUSPEND',
      WRITEOFF: 'WRITEOFF',
      GRACE_PERIOD_REQUESTED: 'GRACE_PERIOD_REQUESTED',
    },

    billingFrequency: {
      MONTHLY: 'MONTHLY',
      BI_MONTHLY: 'BI_MONTHLY',
      QUARTERLY: 'QUARTERLY',
      SEMI_ANNUAL: 'SEMI_ANNUAL',
      ANNUAL: 'ANNUAL',
    },

    invoiceType: {
      SUMMARY: 'SUMMARY',
      DETAIL: 'DETAIL',
      DETAIL_SUPPRESS: 'DETAIL_SUPPRESS',
    },

    invoiceDelivery: {
      DOWNLOAD: 'DOWNLOAD',
      EMAIL: 'EMAIL',
      MAIL: 'MAIL',
    },

    statusOrder: {
      CREATED: 'CREATED',
      PENDING: 'PENDING',
      CANCELLED: 'CANCELLED',
      SUBMITTED: 'SUBMITTED',
      APPROVED: 'APPROVED',
      PROCESSING: 'PROCESSING',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      PROVISIONING_INITIATED: 'PROVISIONING_INITIATED',
      PROVISIONING_ERROR: 'PROVISIONING_ERROR',
      BILLING_ERROR: 'BILLING_ERROR',
    },

    types: {
      NEW: 'NEW',
      MODIFY: 'MODIFY',
      SUSPEND: 'SUSPEND',
      RESUME: 'RESUME',
      CANCEL: 'CANCEL',
      RENEW: 'RENEW',
      OPT_IN: 'OPT_IN',
      OPT_OUT: 'OPT_OUT',
      UPGRADE: 'UPGRADE',
      DOWNGRADE: 'DOWNGRADE',
      TRIAL: 'TRIAL',
      TRIAL_SERVICE: 'TRIAL_SERVICE',
    },

    entityType: {
      ORDER: 'ORDER',
      CUSTOMER: 'CUSTOMER',
      CONTACT: 'CONTACT',
      ADDRESS: 'ADDRESS',
      ITEM: 'ITEM',
      PAYMENT_PROFILE: 'PAYMENT_PROFILE',
      BILLING_PROFILE: 'BILLING_PROFILE',
      PRICE_OFFER: 'PRICE_OFFER',
      BUNDLE: 'BUNDLE',
      PACKAGE: 'PACKAGE',
      DEPENDENCY: 'DEPENDENCY',
      AROPS: 'AROPS',
      IDENTITY: 'IDENTITY',
      TAX: 'TAX',
      PAYMENT: 'PAYMENT',
      COLLECTION: 'COLLECTION',
      INTEGRATION: 'INTEGRATION',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      DISCOUNT_OFFER: 'DISCOUNT_OFFER',
      CUSTOM_ATTRIBUTES: 'CUSTOM_ATTRIBUTES',
      ACCOUNT_INFO: 'ACCOUNT_INFO',
      BILLING: 'BILLING',
      PROVISIONING: 'PROVISIONING',
    },

    paymentMethod: {
      CHECK: 'CHECK',
      CREDIT_CARD: 'CREDIT_CARD',
      NON_PAYING: 'NON_PAYING',
      ECHECK: 'ECHECK',
      MANUAL: 'MANUAL',
    },

    customerSegment: {
      B2B: 'B2B',
      B2C: 'B2C',
      B2B2C: 'B2B2C',
    },

    status: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      CLOSED: 'CLOSED',
    },

    reason: {
      PAYMENT_FAILURE: 'PAYMENT_FAILURE',
      CUSTOMER_REQUEST: 'CUSTOMER_REQUEST',
      COLLECTIONS: 'COLLECTIONS',
      INTERNAL_CONTROL: 'INTERNAL_CONTROL',
      NONE: 'NONE',
    },

    paymentTerm: {
      NET_0: 'NET_0',
      NET_15: 'NET_15',
      NET_30: 'NET_30',
      NET_45: 'NET_45',
      NET_60: 'NET_60',
      NET_75: 'NET_75',
      NET_90: 'NET_90',
    },

    phoneType: {
      WORK: 'WORK',
      HOME: 'HOME',
      MOBILE: 'MOBILE',
      FAX: 'FAX',
    },

    noteStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      DISAPPROVED: 'DISAPPROVED',
      SENT: 'SENT',
      COLLECTION: 'COLLECTION',
      SYNC_FAILED: 'SYNC_FAILED',
      SEND_FAILED: 'SEND_FAILED',
      // STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      // STAMPED: 'STAMPED',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
    },

    arNotesType: {
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
    },

    statusPayment: {
      OPEN: 'OPEN',
      CLOSED: 'CLOSED',
      REVERSED: 'REVERSED',
      REVERSE_ALLOCATED: 'REVERSE_ALLOCATED',
      REVERSED_TO_SUSPENSE: 'REVERSED_TO_SUSPENSE',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
    },

    accountStatementLineType: {
      INVOICE: 'INVOICE',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
      CHARGEBACK: 'CHARGEBACK',
      REFUND: 'REFUND',
      ADJUSTMENT: 'ADJUSTMENT',
      SETTLEMENT: 'SETTLEMENT',
      DISPUTE: 'DISPUTE',
      UNDO_CREDIT_NOTE: 'UNDO_CREDIT_NOTE',
      UNDO_DEBIT_NOTE: 'UNDO_DEBIT_NOTE',
    },

    selectBool: {
      TRUE: 'TRUE',
      FALSE: 'FALSE',
    },

    folioStatus: {
      STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      STAMPED: 'STAMPED',
      CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
      AWAITING_CANCELLATION_STAMPING: 'AWAITING_CANCELLATION_STAMPING',
    },

    switchName: {
      SMS: 'SMS',
      DATA: 'DATA',
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
      DIDWW: 'DIDWW',
      NEWSMS: 'NEWSMS',
    },

    switchNameRawFileData: {
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
    },

    switchNameRawNonBroadsoft: {
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      DIDWW: 'DIDWW',
      TWILIO: 'TWILIO',
    },

    switchNameRawSmsFile: {
      SMS: 'SMS',
      NEWSMS: 'NEWSMS',
    },

    accountCategory: {
      PREPAID: 'PREPAGO',
      POSTPAID: 'POSTPAGO',
      HYBRID: 'HÍBRIDO',
    },
  },
};
